<div class="navbar-top"
     [ngClass]="fuseConfig.layout.navbar.secondaryBackground" style="background-color: whitesmoke !important;">
    <div class="" style=" display: flex; align-items: center; justify-content: center;">
        <!--<img class="logo-icon" src="assets/images/logos/fuse.svg" />-->
        <!--<img class="logo-icon" src="assets/images/logos/EcoEL.png" style="width: 40% !important;  height: 40% !important;margin-top:10px" />-->
        <img class="logo-icon" src="assets/images/logos/ecoLogo.png" style="padding: 25% !important;" />
        <!--<span class="logo-text secondary-text">Minutes</span>-->
    </div>
    <!--class="secondary-text"-->
    <div class="buttons">
        <button mat-icon-button
                class="toggle-sidebar-folded"
                (click)="toggleSidebarFolded()"
                fxHide.lt-lg>
            <mat-icon style="color:black !important">menu</mat-icon>
        </button>

        <button mat-icon-button
                class="toggle-sidebar-opened"
                (click)="toggleSidebarOpened()"
                fxHide.gt-md>
            <mat-icon style="color:black !important">arrow_back</mat-icon>
        </button>
    </div>
</div>

<div class="navbar-scroll-container" 
     [ngClass]="fuseConfig.layout.navbar.primaryBackground"
     fusePerfectScrollbar
     [fusePerfectScrollbarOptions]="{ suppressScrollX: true }">
    <div class="user"
         fxLayout="column"
         [ngClass]="fuseConfig.layout.navbar.secondaryBackground">
        <div class="h3 username">{{this.name}}</div>
        <div class="h5 email hint-text mt-8">{{this.email}}</div>

        <div class="avatar-container"
             [ngClass]="fuseConfig.layout.navbar.primaryBackground">
            <!--<img class="avatar" src="assets/images/avatars/Velazquez.jpg" />-->
            <img class="avatar" src="{{this.userMainImage}}" />
        </div>
    </div>

    <div class="navbar-content">
        <fuse-navigation class="material2" layout="vertical"></fuse-navigation>

    </div>
    <!--<div style="        margin-left: 10%;
        font-size: 11px;
        color: lightgray;"><span>Powered by Sampath IT Solutions V 1.0</span></div>


</div>-->
