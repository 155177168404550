import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';

@Component({
    selector: 'fuse-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FuseNavigationComponent implements OnInit {
    @Input()
    layout = 'vertical';

    @Input()
    navigation: any;
    Reviewer: any;
    Users: any[];

    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     *
     * @param {ChangeDetectorRef} _changeDetectorRef
     * @param {FuseNavigationService} _fuseNavigationService
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _fuseNavigationService: FuseNavigationService
    ) {
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this._fuseNavigationService.getCurrentNavigation();
        //console.log(" this.navigation:", this.navigation);
        // Subscribe to the current navigation changes
        this._fuseNavigationService.onNavigationChanged
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
                this.navigation = this._fuseNavigationService.getCurrentNavigation();

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this._fuseNavigationService.onNavigationItemAdded,
            this._fuseNavigationService.onNavigationItemUpdated,
            this._fuseNavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {

                // Mark for checkEXParticipant
                this._changeDetectorRef.markForCheck();
            });
        //console.log("empermissionlevel get::", sessionStorage.getItem("empermissionlevel"));



        var isAdmnin = false;
        if (sessionStorage.getItem('emparticipantcode') == "PAR") {
            console.log("emparticipantcode", sessionStorage.getItem('emparticipantcode'));
            this._fuseNavigationService.updateNavigationItem('Meetings', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingMinutesmy', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('myActionItems', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('participantverification', {
                hidden: false
            });
            this._fuseNavigationService.getAllMeetings().then((data) => {
                console.log("OrganizingData", data)
                if (data.length != 0) {
                    this._fuseNavigationService.updateNavigationItem('startmeeting', {
                        hidden: false
                    });
                    this._fuseNavigationService.updateNavigationItem('refreshmentorders', {
                        hidden: false

                    });
                }
            });

        }
        if (sessionStorage.getItem('emexparticipantcode') == "EPA") {
            console.log("useAd", sessionStorage.getItem('emexparticipantcode'));
            this._fuseNavigationService.updateNavigationItem('meetingMinutesmy', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('myActionItems', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('participantverification', {
                hidden: false
            });
            this._fuseNavigationService.getAllMeetings().then((data) => {
                console.log("OrganizingData", data)

            });

        }
        if (sessionStorage.getItem('emsecretarycode') == "SCT") {
            console.log("emsecretarycode", sessionStorage.getItem('emsecretarycode'));
            this._fuseNavigationService.updateNavigationItem('organizationregister', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingMinutesmy', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('myActionItems', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('participantverification', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('Meetings', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('MeetingRoomDetail', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingbyrooms', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('ReferenceData', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingMinutesadmin', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('userManagement', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('externalParticipant', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('MeetingMinutes', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('allActionItems', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('allDiscussionPoints', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingrooms', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingcategory', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('startmeeting', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('verifyyourparticipant', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('initiatedmeetingdetails', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('externalParticipant', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('initmeet', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingproject', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('departments', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('boardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('submitboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('myboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('reviewboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('viewboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('usergroups', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('refreshmentsetup', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('refreshments', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('refreshmentorders', {
                hidden: false

            });
            //console.log("Trueeeeeeeeeeee");
            this._fuseNavigationService.updateNavigationItem('refreshmentrequest', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('finalizeorder', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('completedorders', {
                hidden: false
            });

            this._fuseNavigationService.updateNavigationItem('refreshmentsupplier', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('mattersarisingandactionitem', {
                hidden: false
            });
        }
        if (sessionStorage.getItem('emadmincode') == "ADM") {
            this._fuseNavigationService.updateNavigationItem('mattersarisingandactionitem', {
                hidden: false
            });
            console.log("useAd", sessionStorage.getItem("emadmincode"));
            this._fuseNavigationService.updateNavigationItem('MeetingRoomDetail', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('organizationregister', {
                hidden: false
            });
           
            this._fuseNavigationService.updateNavigationItem('managemeetingrooms', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingMinutesmy', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('myActionItems', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('participantverification', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('Meetings', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingbyrooms', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('ReferenceData', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingMinutesadmin', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('userManagement', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('externalParticipant', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('MeetingMinutes', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('allActionItems', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('allDiscussionPoints', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingrooms', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingcategory', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('startmeeting', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('verifyyourparticipant', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('initiatedmeetingdetails', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('externalParticipant', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('initmeet', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingproject', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('departments', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('boardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('submitboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('myboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('reviewboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('viewboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('usergroups', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('refreshmentsetup', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('refreshments', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('refreshmentorders', {
                hidden: false

            });
            //console.log("Trueeeeeeeeeeee");
            this._fuseNavigationService.updateNavigationItem('refreshmentrequest', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('finalizeorder', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('completedorders', {
                hidden: false
            });

            this._fuseNavigationService.updateNavigationItem('refreshmentsupplier', {
                hidden: false
            });
           
        }
        if (sessionStorage.getItem('emecoadmincode') == "ECA") {
            this._fuseNavigationService.updateNavigationItem('MeetingRoomDetail', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('organizationregister', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('mattersarisingandactionitem', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('managemeetingrooms', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingMinutesmy', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('myActionItems', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('participantverification', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('Meetings', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingbyrooms', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('ReferenceData', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingMinutesadmin', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('userManagement', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('externalParticipant', {
                hidden: false
            });

            this._fuseNavigationService.updateNavigationItem('MeetingMinutes', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('allActionItems', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('allDiscussionPoints', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingrooms', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingcategory', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('departments', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('startmeeting', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('verifyyourparticipant', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('initiatedmeetingdetails', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('externalParticipant', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('initmeet', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('meetingproject', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('boardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('submitboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('myboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('reviewboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('viewboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('usergroups', {
                hidden: false
            });

            this._fuseNavigationService.updateNavigationItem('refreshmentsetup', {
                hidden: false
            });

            this._fuseNavigationService.updateNavigationItem('refreshments', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('refreshmentorders', {
                hidden: false

            });
            console.log("Trueeeeeeeeeeee");
            this._fuseNavigationService.updateNavigationItem('refreshmentrequest', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('finalizeorder', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('completedorders', {
                hidden: false
            });

            this._fuseNavigationService.updateNavigationItem('refreshmentsupplier', {
                hidden: false
            });

        }
        if (sessionStorage.getItem('emboardmembercode') == "BMG") {
            console.log("emboardmembercode", sessionStorage.getItem("emboardmembercode"));
            this._fuseNavigationService.updateNavigationItem('organizationregister', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('Meetings', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('boardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('viewboardpapers', {
                hidden: false
            });

            this._fuseNavigationService.getAllMeetings().then((data) => {
                console.log("OrganizingData", data)
                if (data.length != 0) {
                    this._fuseNavigationService.updateNavigationItem('startmeeting', {
                        hidden: false
                    });
                    this._fuseNavigationService.updateNavigationItem('refreshmentorders', {
                        hidden: false

                    });
                }
            });

        }
        if (sessionStorage.getItem('empaperapprovercode') == "PAG") {
            console.log("empaperapprovercode", sessionStorage.getItem("empaperapprovercode"));
            this._fuseNavigationService.updateNavigationItem('Meetings', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('organizationregister', {
                hidden: false
            });
            console.log("userG2", sessionStorage.getItem("emappUserId"));
            this._fuseNavigationService.updateNavigationItem('boardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('viewboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.getAllMeetings().then((data) => {
                console.log("OrganizingData", data)
                if (data.length != 0) {
                    this._fuseNavigationService.updateNavigationItem('startmeeting', {
                        hidden: false
                    });
                    this._fuseNavigationService.updateNavigationItem('refreshmentorders', {
                        hidden: false

                    });
                }
            });

        }
        if (sessionStorage.getItem('empaperreviewercode') == "PRG") {
            console.log("empaperreviewercode", sessionStorage.getItem("empaperreviewercode"));
            this._fuseNavigationService.updateNavigationItem('Meetings', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('organizationregister', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('boardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('reviewboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.getAllMeetings().then((data) => {
                console.log("OrganizingData", data)
                if (data.length != 0) {
                    this._fuseNavigationService.updateNavigationItem('startmeeting', {
                        hidden: false
                    });
                    this._fuseNavigationService.updateNavigationItem('refreshmentorders', {
                        hidden: false

                    });
                }
            });

        }
        if (sessionStorage.getItem('empapersubmissioncode') == "PSG") {
            console.log("userG", sessionStorage.getItem("empapersubmissioncode"));
            this._fuseNavigationService.updateNavigationItem('Meetings', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('organizationregister', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('boardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('submitboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('myboardpapers', {
                hidden: false
            });
            this._fuseNavigationService.getAllMeetings().then((data) => {
                console.log("OrganizingData", data)
                if (data.length != 0) {
                    this._fuseNavigationService.updateNavigationItem('startmeeting', {
                        hidden: false
                    });
                    this._fuseNavigationService.updateNavigationItem('refreshmentorders', {
                        hidden: false

                    });
                }
            });

        }

        if (sessionStorage.getItem('emrefreshmentadmincode') == "RAP") {
            //console.log("RefresgAd")
            console.log("userG", sessionStorage.getItem("emrefreshmentadmincode"));
            this._fuseNavigationService.updateNavigationItem('refreshmentsetup', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('refreshments', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('refreshmentrequest', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('finalizeorder', {
                hidden: false
            });
            this._fuseNavigationService.updateNavigationItem('completedorders', {
                hidden: false
            });

            this._fuseNavigationService.updateNavigationItem('refreshmentsupplier', {
                hidden: false
            });
            this._fuseNavigationService.getAllMeetings().then((data) => {
                console.log("OrganizingData", data)
                if (data.length != 0) {
                    this._fuseNavigationService.updateNavigationItem('startmeeting', {
                        hidden: false
                    });
                    this._fuseNavigationService.updateNavigationItem('refreshmentorders', {
                        hidden: false

                    });
                }
            });


        }
        //this.Users = [];
        //this._fuseNavigationService.getAllAppUsers().then((data) => {
        //    if (data != undefined) {

        //        data.forEach(user => {
        //            var tempUser = {
        //                id: user.id,
        //                name: user.name,
        //                //avatar: user.appUserDocuments.find(doc => doc.type == "Main").documentUrl,
        //            }
        //            this.Users.push(tempUser);
        //        });
        //        console.log("Users", this.Users);
        //        sessionStorage.setItem('emUsers', JSON.stringify(this.Users));
        //        //console.log("emUsers", JSON.parse(sessionStorage.getItem('emUsers')));
        //    }
        //});

        //if (sessionStorage.getItem("empermissionlevel") === "Participant") {
        //    //this._fuseNavigationService.removeNavigationItem('meetingMinutesadmin');
        //    //this._fuseNavigationService.removeNavigationItem('userManagement');
        //    //this._fuseNavigationService.removeNavigationItem('MeetingMinutes');
        //    //this._fuseNavigationService.removeNavigationItem('allActionItems');
        //    //this._fuseNavigationService.removeNavigationItem('allDiscussionPoints');
        //    //this._fuseNavigationService.removeNavigationItem('meetingrooms');
        //    //this._fuseNavigationService.removeNavigationItem('meetingcategory');
        //    //this._fuseNavigationService.removeNavigationItem('startmeeting');
        //    //this._fuseNavigationService.removeNavigationItem('verifyyourparticipant'); 
        //    //this._fuseNavigationService.removeNavigationItem('initiatedmeetingdetails');   
        //    //this._fuseNavigationService.removeNavigationItem('externalParticipant');


        //    this._fuseNavigationService.updateNavigationItem('meetingproject', {
        //        hidden: true
        //    });

        //    this._fuseNavigationService.updateNavigationItem('meetingMinutesadmin', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('userManagement', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('MeetingMinutes', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('allActionItems', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('allDiscussionPoints', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('meetingrooms', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('meetingcategory', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('startmeeting', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('verifyyourparticipant', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('initiatedmeetingdetails', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('externalParticipant', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('initmeet', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('meetingroomscollapse', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('ReferenceData', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('usergroups', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('submitboardpapers', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('myboardpapers', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('reviewboardpapers', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('viewboardpapers', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('boardpapers', {
        //        hidden: true
        //    });

        //    this._fuseNavigationService.getAllUserGroups().then((data) => {
        //        data.forEach(usergroup => {
        //            console.log("userG", usergroup);
        //            if (usergroup) {

        //                this._fuseNavigationService.updateNavigationItem('boardpapers', {
        //                    hidden: false
        //                });
        //                if (usergroup.participantId == sessionStorage.getItem("emappUserId") && usergroup.code == "PSG") {
        //                    console.log("userG", sessionStorage.getItem("emappUserId"));
        //                    this._fuseNavigationService.updateNavigationItem('submitboardpapers', {
        //                        hidden: false
        //                    });
        //                    this._fuseNavigationService.updateNavigationItem('myboardpapers', {
        //                        hidden: false
        //                    });
        //                }
        //                if (usergroup.participantId == sessionStorage.getItem("emappUserId") && usergroup.code == "PRG") {
        //                    console.log("userG1", sessionStorage.getItem("emappUserId"));
        //                    this._fuseNavigationService.updateNavigationItem('reviewboardpapers', {
        //                        hidden: false
        //                    });
        //                }
        //                if (usergroup.participantId == sessionStorage.getItem("emappUserId") && usergroup.code == "BMG") {
        //                    console.log("userG2", sessionStorage.getItem("emappUserId"));
        //                    this._fuseNavigationService.updateNavigationItem('viewboardpapers', {
        //                        hidden: false
        //                    });
        //                }
        //                if (usergroup.participantId == sessionStorage.getItem("emappUserId") && usergroup.code == "PAG") {
        //                    console.log("userG2", sessionStorage.getItem("emappUserId"));
        //                    this._fuseNavigationService.updateNavigationItem('viewboardpapers', {
        //                        hidden: false
        //                    });
        //                }
        //            }
        //            else {

        //                this._fuseNavigationService.updateNavigationItem('boardpapers', {
        //                    hidden: true
        //                });

        //            }
        //        });


        //    });
        //    this._fuseNavigationService.updateNavigationItem('refreshments', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('refreshmentorders', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('refreshmentrequest', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('finalizeorder', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('completedorders', {
        //        hidden: true
        //    });

        //    this._fuseNavigationService.getRefreshmentUser().then((value) => {
        //        var isAdmnin = false;
        //        value.forEach(userPermission => {

        //            console.log("userG", userPermission);

        //            if (userPermission.participantId == sessionStorage.getItem("emappUserId") ) {

        //                if ( userPermission.userGroup == "Refreshment Admin Permission") {

        //                    this._fuseNavigationService.updateNavigationItem('refreshments', {
        //                        hidden: false
        //                    });
        //                    this._fuseNavigationService.updateNavigationItem('refreshmentrequest', {
        //                        hidden: false
        //                    });
        //                    this._fuseNavigationService.updateNavigationItem('finalizeorder', {
        //                        hidden: false
        //                    });
        //                    this._fuseNavigationService.updateNavigationItem('completedorders', {
        //                        hidden: false
        //                    });
        //                    this._fuseNavigationService.updateNavigationItem('refreshmentorders', {
        //                        hidden: true
        //                    });
        //                     isAdmnin = true;
        //                } else if (!isAdmnin)
        //                {

        //                    this._fuseNavigationService.updateNavigationItem('refreshmentorders', {
        //                        hidden: false
        //                    });
        //                    this._fuseNavigationService.updateNavigationItem('completedorders', {
        //                        hidden: false
        //                    });
        //                }
        //            }

        //        });


        //    });

        //}

        //if (sessionStorage.getItem("empermissionlevel") === "External Participant") {
        //    //this._fuseNavigationService.removeNavigationItem('meetingMinutesadmin');
        //    //this._fuseNavigationService.removeNavigationItem('userManagement');
        //    //this._fuseNavigationService.removeNavigationItem('MeetingMinutes');
        //    //this._fuseNavigationService.removeNavigationItem('allActionItems');
        //    //this._fuseNavigationService.removeNavigationItem('allDiscussionPoints');
        //    //this._fuseNavigationService.removeNavigationItem('meetingrooms');
        //    //this._fuseNavigationService.removeNavigationItem('meetingcategory');
        //    //this._fuseNavigationService.removeNavigationItem('startmeeting');
        //    //this._fuseNavigationService.removeNavigationItem('verifyyourparticipant');
        //    //this._fuseNavigationService.removeNavigationItem('initmeet');
        //    //this._fuseNavigationService.removeNavigationItem('initiatedmeetingdetails');  
        //    //this._fuseNavigationService.removeNavigationItem('externalParticipant');
        //    this._fuseNavigationService.updateNavigationItem('meetingMinutesadmin', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('userManagement', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('MeetingMinutes', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('allActionItems', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('allDiscussionPoints', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('meetingrooms', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('meetingcategory', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('startmeeting', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('verifyyourparticipant', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('initiatedmeetingdetails', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('externalParticipant', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('initmeet', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('meetingproject', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('ReferenceData', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('usergroups', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('submitboardpapers', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('myboardpapers', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('reviewboardpapers', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('viewboardpapers', {
        //        hidden: true
        //    });
        //    this._fuseNavigationService.updateNavigationItem('boardpapers', {
        //        hidden: true
        //    });

        //}


        //if (sessionStorage.getItem("empermissionlevel") === "Adminstrator") {

        //    this._fuseNavigationService.updateNavigationItem('meetingMinutesadmin', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('userManagement', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('MeetingMinutes', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('allActionItems', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('allDiscussionPoints', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('meetingrooms', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('meetingcategory', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('startmeeting', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('verifyyourparticipant', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('initiatedmeetingdetails', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('externalParticipant', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('initmeet', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('meetingproject', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('submitboardpapers', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('myboardpapers', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('reviewboardpapers', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('viewboardpapers', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('usergroups', {
        //        hidden: false
        //    });

        //}

        //if (sessionStorage.getItem("empermissionlevel") === "Global Adminstrator") {
        //    console.log("per", sessionStorage.getItem("empermissionlevel"));
        //    this._fuseNavigationService.updateNavigationItem('meetingMinutesadmin', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('userManagement', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('MeetingMinutes', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('allActionItems', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('allDiscussionPoints', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('meetingrooms', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('meetingcategory', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('startmeeting', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('verifyyourparticipant', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('initiatedmeetingdetails', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('externalParticipant', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('initmeet', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('meetingproject', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('submitboardpapers', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('myboardpapers', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('reviewboardpapers', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('viewboardpapers', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('usergroups', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('refreshments', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('refreshmentorders', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('refreshmentrequest', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('finalizeorder', {
        //        hidden: false
        //    });
        //    this._fuseNavigationService.updateNavigationItem('completedorders', {
        //        hidden: false
        //    });

        //}


    }




}