import { Component } from '@angular/core';

@Component({
    selector   : 'footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class FooterComponent
{
    /**
     * Constructor
     */

    currentYear: number;
    constructor()
    {
       // this.currentYear = new Date()).getFullYear();
        //alert((new Date()).getFullYear());

        this.currentYear = (new Date()).getFullYear();
    }
}
