import { Injectable } from '@angular/core'
import { Action } from '@ngrx/store'
//import { Action } from 'rxjs/internal/scheduler/Action'
import { CalendarDataModel , DefaultDataModel } from "../models/calendar.model"
export const ADD_CALENDARDATA = 'ADD_CALENDARDATA'
export const ADD_DEFAULTCALENDARDATA = 'ADD_DEFAULTCALENDARDATA'

export class AddCalendarData implements Action {
    readonly type = ADD_CALENDARDATA;

    constructor(public payload: CalendarDataModel) { }
}
export class AddDefaultCalendarData implements Action {
    readonly type = ADD_DEFAULTCALENDARDATA;

    constructor(public payload: DefaultDataModel) { }
}


export type Actions = AddCalendarData |  AddDefaultCalendarData;