import { Action } from '@ngrx/store'
import { CalendarDataModel , DefaultDataModel} from "../models/calendar.model"
import * as CalendarData from "../actions/calendar.action"

const initialState: CalendarDataModel = {
    CalendarDatalist: [],
}
const inlState: DefaultDataModel = {
    DefaultDatalist: [],
}

export function CalendarReducer(state: CalendarDataModel[] = [initialState], action: CalendarData.Actions) {

    switch (action.type) {
        case CalendarData.ADD_CALENDARDATA:
            state = [];
            return [...state, action.payload];
        default:
            return state;

    }
}
export function DefaultCalendarReducer( statein: DefaultDataModel[] = [inlState], action: CalendarData.Actions) {

    switch (action.type) {
        case CalendarData.ADD_DEFAULTCALENDARDATA:
            statein = [];
            return [...statein, action.payload];
        default:
            return statein;

    }
}