import { Component, OnDestroy, OnInit, ViewEncapsulation, ChangeDetectorRef, AfterContentChecked } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { Router } from '@angular/router';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { DataService } from "./data.service";

import { navigation } from 'app/navigation/navigation';
import { OverlayRef } from '@angular/cdk/overlay';
import { GlobalChatService } from '../../../main/ui/GlobalChat/GlobalChat.service';
/*import { DashboardService} from "../../../main/ui/Dashboard/dashboard.service";*/
import { ToolbarService } from 'app/layout/components/toolbar/toolbar.service';

@Component({
    selector     : 'toolbar',
    templateUrl  : './toolbar.component.html',
    styleUrls    : ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy
{
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];

    message: string;

    // Private
    private _unsubscribeAll: Subject<any>;
    name: string;
    permissionLevel: string;
    Module: string;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private _router: Router,
        private data: DataService, /*private cdref: ChangeDetectorRef*/
        //private sidebarService: GlobalChatService
        private _toolbarService: ToolbarService,
    )
    {
        // Set the defaults
        this.userStatusOptions = [
            {
                'title': 'Online',
                'icon' : 'icon-checkbox-marked-circle',
                'color': '#4CAF50'
            },
            {
                'title': 'Away',
                'icon' : 'icon-clock',
                'color': '#FFC107'
            },
            {
                'title': 'Do not Disturb',
                'icon' : 'icon-minus-circle',
                'color': '#F44336'
            },
            {
                'title': 'Invisible',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#BDBDBD'
            },
            {
                'title': 'Offline',
                'icon' : 'icon-checkbox-blank-circle-outline',
                'color': '#616161'
            }
        ];

        this.languages = [
            {
                id   : 'en',
                title: 'English',
                flag : 'us'
            },
            {
                id   : 'tr',
                title: 'Turkish',
                flag : 'tr'
            }
        ];

        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.data.currentMessage.subscribe(message => this.message = message);
    }
    MeetingSubject: any;
    MeetingLocation: any;
    MeetingDate: any;
    MeetingTime: any;
    MeetingId: any;
    Nomeeting: any;

    //sidebarRef: OverlayRef;

    //chat() {
    //    if (this.sidebarRef) {
    //        this.sidebarService.close();
    //        this.sidebarRef = null;
    //    } else {
    //        this.sidebarRef = this.sidebarService.open();
    //    }
    //    //const dialogLogin = this.dialog.open(GlobalChatComponent, {});
    //}

    //ngAfterContentChecked() {

    //    this.cdref.detectChanges();

    //}

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.Nomeeting = false;
        // Subscribe to the config changes
        console.log("emappUserId", sessionStorage.getItem('emappUserId'));
        this._toolbarService.GetMeetingDetails(parseInt(sessionStorage.getItem('emappUserId'))).then((value) => {
            console.log("GetMeetingDetails", value);
            if (value[0] != null) {
                console.log(value[0].meetingMaster.id);
                this.MeetingId = value[0].meetingMaster.id;
                this.MeetingSubject = value[0].meetingMaster.meetingTitle;
                this.MeetingLocation = value[0].meetingMaster.meetingLocation;
                this.MeetingDate = value[0].meetingMaster.meetingDate;
                this.MeetingTime = value[0].meetingMaster.meetingStartTime;
            } else {
                this.Nomeeting = true;
            }

        });
       

        //console.log("SubsCrib Msg:", this.message);

        //this.name = localStorage.getItem('eminutesname');
        this.name = sessionStorage.getItem("emname");
        this.permissionLevel = sessionStorage.getItem("empermissionlevel");


        //sessionStorage.setItem('empermissionlevel', data.permissionLevel);
        this.Module = "";

        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, {'id': this._translateService.currentLang});
    }

    vieMeeting() {
        sessionStorage.setItem("MeetingID", this.MeetingId);
        // sessionStorage.user = JSON.stringify(this.MeetingId);
        this._router.navigate(['/ui/meetingminutesmy']);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }


    signOut(): void {

        //localStorage.setItem('eminutesname', '');
        //localStorage.setItem('eminutesemail', '');
        //localStorage.setItem('eminutesjwt', null);
        sessionStorage.setItem("emname", '');
        sessionStorage.setItem('ememail', '');
        sessionStorage.setItem('emjwt', '');
        this._router.navigate(['/pages/authentication/login']);
    }
    //MovetoSearchPage(): void  {
    //    this._router.navigate(['/ui/globalsearch']);
    //}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void
    {
        // Do your search here...
        console.log(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void
    {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
    }
}
