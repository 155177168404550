import { Injectable } from '@angular/core'
import { Action } from '@ngrx/store'

import { MeetingsChartData } from "../models/meetings.model"

export const ADD_MeetingCHARTDATA = 'ADD_MeetingCHARTDATA'

export class AddMeetingsChartData implements Action {
    readonly type = ADD_MeetingCHARTDATA;

    constructor(public payload: MeetingsChartData) { }
}

export type Actions =  AddMeetingsChartData;