<style>
  /*  #pot {
        bottom: 15%;
        position: absolute;
        -webkit-animation: linear infinite;
        -webkit-animation-name: run;
        -webkit-animation-duration: 5s;
    }

    @-webkit-keyframes run {
        0% {
            left: 0;
        }

        50% {
            left: 100%;
        }

        100% {
            left: 0;
        }
    }*/
</style>
<mat-toolbar class="p-0 mat-elevation-z1">

    <div class="header accent p-24 h-60"
         fxLayout="row"
         fxLayoutAlign="start center" style="width:% !important;background-color:#262933 !important">
        <div fxLayout="column" fxLayoutAlign="center start" style="width:100%">
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
                <mat-icon class="secondary-text s-18">home</mat-icon>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text" style="color:white !important">
                    {{this.message}}
                </span>
            </div>
            <!--<div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">

                <span *ngIf="!Nomeeting" style="font-size: 11px; color:#d6ffd8; font-weight:600;">
                    Next |
                </span>
                <div style="white-space: initial;">
                    <span *ngIf="!Nomeeting" style="font-size: 11px; color:#ffbd85; font-weight:600;">  {{MeetingSubject}} will be on {{MeetingDate | date}} {{ MeetingTime}} at {{MeetingLocation}}</span>
                    <button *ngIf="!Nomeeting" mat-icon-button color="warn" (click)="vieMeeting()" title="View Meeting" style="margin-left: 5px; color:#0abb39 !important">
                        <mat-icon class="tblActionIcon">open_in_new</mat-icon>
                    </button>
                </div>-->
                <!--<span *ngIf="Nomeeting" style="font-size: large;text-align:center !important;color:#da3b00; font-weight:600;">Welcome to EMMA</span>-->
            <!--</div>-->
            <!--<div class="h2 mt-12">My Meeting Minutes</div>-->
        </div>
        <!--<div style="margin-left:300px;margin-top:8px;font-size:12px !important">
            <a (click)="MovetoSearchPage()" style="color:white">Search in ECO</a> <mat-icon (click)="MovetoSearchPage()" style="margin-left:6px;margin-top:3px;">search</mat-icon>
        </div>-->
    </div>


    <div class="header accent p-24 h-60"
         fxLayout="row"
         fxLayoutAlign="start center" style="width:70% !important;background-color:#262933 !important">
        <div fxLayout="column" fxLayoutAlign="center start" style="width:100%">
            <!--<div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">
                <mat-icon class="secondary-text s-18">home</mat-icon>
                <mat-icon class="secondary-text s-16">chevron_right</mat-icon>
                <span class="secondary-text" style="color:white !important">
                    {{this.message}}
                </span>
            </div>-->
            <div fxLayout="row" fxLayoutAlign="start center" fxFlex="100">

                <!--<span *ngIf="!Nomeeting" style="font-size: 11px; color:#d6ffd8; font-weight:600;">
                    Next |
                </span>-->
                <div style="        font-size: 11px;
        color: #ffbd85;
        font-weight: 600; /* word-break: break-all; */
        white-space: initial;
        display: inline-block;
        height: auto;
        /* margin: 3px auto; */
        line-height: 0;
        -webkit-line-clamp: 1;
        margin-top: 2%;
        -webkit-box-orient: vertical;">
                    <span *ngIf="!Nomeeting" > <span style="font-size: 11px; color:#d6ffd8 !important; font-weight:600;"> Next | </span> {{MeetingSubject}} will be on {{MeetingDate | date}} {{ MeetingTime}} at {{MeetingLocation}}</span>
                    <button *ngIf="!Nomeeting" mat-icon-button color="warn" (click)="vieMeeting()" title="View Meeting" style="margin-left: 5px; color:#0abb39 !important">
                        <mat-icon class="tblActionIcon">open_in_new</mat-icon>
                    </button>
                </div>
                <!--<span *ngIf="Nomeeting" style="font-size: large;text-align:center !important;color:#da3b00; font-weight:600;">Welcome to EMMA</span>-->
            </div>
            <!--<div class="h2 mt-12">My Meeting Minutes</div>-->
        </div>
        <!--<div style="margin-left:300px;margin-top:8px;font-size:12px !important">
            <a (click)="MovetoSearchPage()" style="color:white">Search in ECO</a> <mat-icon (click)="MovetoSearchPage()" style="margin-left:6px;margin-top:3px;">search</mat-icon>
        </div>-->
    </div>


    <div style="height: 95% !important;
        background-color: #262933 !important;">
        <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">
            <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center" style="height:95% !important">
                <button mat-icon-button
                        class="navbar-toggle-button"
                        *ngIf="!hiddenNavbar && !rightNavbar"
                        (click)="toggleSidebarOpen('navbar')"
                        fxHide.gt-md>
                    <mat-icon class="secondary-text" style="color:white !important">menu</mat-icon>
                </button>

                <div class="toolbar-separator"
                     *ngIf="!hiddenNavbar && !rightNavbar"
                     fxHide.gt-md></div>

                <div fxLayout="row"
                     fxLayoutAlign="start center" style="height:95% !important"
                     *ngIf="horizontalNavbar">
                    <div class="logo ml-16">
                        <img class="logo-icon" src="assets/images/logos/fuse.svg" />
                    </div>
                </div>

                <div class="px-8 px-md-16">
                    <!-- <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts>-->
                </div>
            </div>

            <div class=""
                 fxFlex="0 1 auto"
                 fxLayout="row"
                 fxLayoutAlign="start center" style="height:95% !important">
                <button mat-button
                        [matMenuTriggerFor]="userMenu"
                        class="user-button">
                    <div fxLayout="row" fxLayoutAlign="center center" style="height:95% !important">
                        <!--<img class="avatar mr-0 mr-sm-16"
                         src="assets/images/avatars/Velazquez.jpg" />-->
                        <!--<span>{{this.message}}</span>-->
                        <span class="username mr-12" fxHide fxShow.gt-sm style="color:#6dff6d !important">{{this.name}}</span>
                        <span class="username mr-12" fxHide fxShow.gt-sm style="        font-weight: 400;
        color: #cacaca;
        font-family: monospace;
"><!--({{this.permissionLevel}})--></span>

                        <mat-icon class="s-16" style=" color:white">keyboard_arrow_down</mat-icon>
                    </div>
                </button>

                <mat-menu #userMenu="matMenu" [overlapTrigger]="false">
                    <!--<button mat-menu-item>
                        <mat-icon>account_circle</mat-icon>
                        <span>My Profile</span>
                    </button>

                    <button mat-menu-item class="">
                        <mat-icon>mail</mat-icon>
                        <span>Inbox</span>
                    </button>-->

                    <button mat-menu-item class="" (click)="signOut()">
                        <mat-icon>exit_to_app</mat-icon>
                        <span>Logout</span>
                    </button>
                </mat-menu>

                <!-- <div class="toolbar-separator"></div> -->
                <!-- <fuse-search-bar (input)="search($event)"></fuse-search-bar> -->
                <!-- <div class="toolbar-separator"></div> -->
                <!-- <button
                    mat-button
                    fxHide
                    fxShow.gt-xs
                    class="language-button"
                    [matMenuTriggerFor]="languageMenu"
                >
                    <div fxLayout="row" fxLayoutAlign="center center">
                        <img
                            class="flag mr-8"
                            [src]="
                                'assets/icons/flags/' +
                                selectedLanguage.flag +
                                '.png'
                            "
                        />
                        <span class="iso text-uppercase">{{
                            selectedLanguage.id
                        }}</span>
                    </div>
                </button> -->

                <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">
                    <button mat-menu-item
                            *ngFor="let lang of languages"
                            (click)="setLanguage(lang)">
                        <span fxLayout="row" fxLayoutAlign="start center">
                            <img class="flag mr-16"
                                 [src]="'assets/icons/flags/' + lang.flag + '.png'" />
                            <span class="iso">{{ lang.title }}</span>
                        </span>
                    </button>

                    <button mat-menu-item
                            [routerLink]="'/components/multi-language'">
                        <div fxLayout="row" fxLayoutAlign="start center">
                            <span>Learn more</span>
                        </div>
                    </button>
                </mat-menu>

                <!-- <div class="toolbar-separator" fxHide fxShow.gt-xs></div> -->
                <!--<button
                    mat-icon-button

                    class="chat-panel-toggle-button"
                    (click)="chat()"
                    aria-label="Toggle chat panel"
                >
                    <mat-icon class="secondary-text">chat</mat-icon>
                </button>

                <div class="toolbar-separator" fxHide.gt-md></div>-->
                <!-- <button
                    mat-icon-button
                    class="quick-panel-toggle-button"
                    (click)="toggleSidebarOpen('quickPanel')"
                    aria-label="Toggle quick panel"
                >
                    <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
                </button> -->
                <!-- <div
                    class="toolbar-separator"
                    *ngIf="!hiddenNavbar && rightNavbar"
                    fxHide
                    fxShow.gt-xs
                ></div> -->
                <!-- <button
                    mat-icon-button
                    class="navbar-toggle-button"
                    *ngIf="!hiddenNavbar && rightNavbar"
                    (click)="toggleSidebarOpen('navbar')"
                    fxHide.gt-md
                > -->
                <!-- <mat-icon class="secondary-text">menu</mat-icon>
                </button> -->
            </div>
        </div>
    </div>
</mat-toolbar>
