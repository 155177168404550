import { Injectable, Inject, EventEmitter } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import {
    ActivatedRouteSnapshot,
    Resolve,
    RouterStateSnapshot,
} from "@angular/router";
import { BehaviorSubject, from, Observable, Subject } from "rxjs";
import { FuseUtils } from "@fuse/utils";
import * as signalR from '@aspnet/signalr';
import { List } from 'lodash';


@Injectable()
export class ToolbarService  {
    departments: any[];
    Appusers: any[];
    updateData: any[];
    Departments: any[];
    //recievedMsg: any[];
    _baseUrl: string;
    onContactsChanged: BehaviorSubject<any>;


    ///////chat
    public connection: signalR.HubConnection;
    allAppUsers: any;


    allUsers = new EventEmitter<any[]>();
    newUser = new EventEmitter<IUser>();
    leftUser = new EventEmitter<IUser>();
    recievedMsg = new EventEmitter<List<any>>();
    updateMsg = new EventEmitter<List<any>>();

    /////chat end

    constructor(private _httpClient: HttpClient, @Inject('BASE_URL') baseUrl: string) {
        console.log(baseUrl);
        this._baseUrl = baseUrl;

        ///////////////chat configuration
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl("/chatHub")
            .configureLogging(signalR.LogLevel.Information)
            .build();


        (async () => {
            try {
                await this.connection.start();
                const connectionId = await this.connection.invoke('GetConnectionId');
                console.log("ChatHub connectionID", connectionId);
                console.log("Client connected to ChatHub");
            } catch (err) {
                console.log(err);
            }
        })();

        this.connection.on("allLoggedUsers", (allUsers) => {
            this.allUsers.emit(allUsers);
        });

        this.connection.on("newUser", (name, email) => {
            const Msginterface: IUser = { name: name, email: email }
            this.newUser.emit(Msginterface);
        });

        this.connection.on("leftUser", (name, email) => {
            const Msginterface: IUser = { name: name, email: email }
            this.leftUser.emit(Msginterface);
        });

        this.connection.on("whoYouAre", async () => {
            var name = sessionStorage.getItem("emname");
            var email = sessionStorage.getItem("ememail");
            await this.connection.invoke("Connect", name, email);
        });

        this.connection.on("MsgRecieved", (FromUserEmail) => {
            console.log("update msgreciver", FromUserEmail);
            this.UpdateMsg(FromUserEmail);
        });
        ///////////////chat configuration end


        this.onContactsChanged = new BehaviorSubject([]);
    }
   

    saveRefreshments(formData): Promise<any> {
        return new Promise((resolve, reject) => {
        
            
            this._httpClient
                .post(this._baseUrl + "api/Refreshments", formData)
                .subscribe((response: any) => {
                    // this.contacts = {};
                    this.departments = response;
                    console.log("response:", this.departments)
                    resolve(this.departments);
                    //resolve(this.contacts);
                    // setTimeout(() => {
                    //     resolve(this.contacts);
                    // }, 100000);
                }, reject);
        });
    }
    updateRefreshments(formData): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(this._baseUrl + "api/Refreshments", formData)
                .subscribe((response: any) => {

                    this.updateData = response;
                    console.log("updateUserData:", this.updateData)
                    resolve(this.updateData);

                }, reject);
        });
    }
    getUserById(): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient
                .get(this._baseUrl + "api/AppUserByID")
                .subscribe((response: any) => {
                    this.updateData = response;
                    console.log("AppUser:", this.updateData)
                    resolve(this.updateData);
                }, reject);
        });
    }

    updateUser(formData): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .put(this._baseUrl + "api/PasswordChange", formData)
                .subscribe((response: any) => {

                    this.updateData = response;
                    //console.log("updateUserData:", this.updateUserData)
                    resolve(this.updateData);

                }, reject);
        });
    }

    GetAllRefreshments(): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient
                .get(this._baseUrl + "api/Refreshments")
                .subscribe((response: any) => {
                    this.Departments = response;
                    resolve(this.Departments);
                    console.log("Refreshments", response);
                }, reject);

        });
    }
    GetAllSuppliers(): Promise<any> {
        return new Promise((resolve, reject) => {

            this._httpClient
                .get(this._baseUrl + "api/RefreshmentSupplierAdd")
                .subscribe((response: any) => {
                    this.updateData = response;
                    resolve(this.updateData);
                }, reject);
        });
    }
    GetMeetingDetails(UserId: number): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(this._baseUrl + "api/DashboardMeeting/" + UserId)
                .subscribe((response: any) => {
                    this.updateData = response;
                    resolve(this.updateData);
                }, reject);
        });
    }


    ////////////chat
    async sendMsg(msgObj) {
        this.sendMsgDB(msgObj);
        var email = msgObj.ToUser;
        console.log("msg send to", email);
        await this.connection.invoke("MsgSent", email);
    }
    sendMsgDB(msgObj): Promise<any> {
            return new Promise((resolve, reject) => {


                this._httpClient
                    .post(this._baseUrl + "api/ChatConversation", msgObj)
                    .subscribe((response: any) => {

                        var UserGroup = response;
                        console.log("response:", UserGroup);

                    }, reject);
            });
    }

    UpdateMsg(FromUser: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(this._baseUrl + "api/ChatConversation/" + FromUser)
                .subscribe((response: any) => {
                    var tempMsg = response;
                    this.updateMsg.emit(tempMsg);
                    resolve(this.recievedMsg);
                }, reject);
        });
    }

    getMsg(FromUser: string): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(this._baseUrl + "api/ChatConversationGetAll/" + FromUser)
                .subscribe((response: any) => {
                    var tempMsg = response;
                    this.recievedMsg.emit(tempMsg);
                    resolve(this.recievedMsg);
                }, reject);
        });
    }


    getAllAppUsers(): Promise<any> {
        return new Promise((resolve, reject) => {
            this._httpClient
                .get(this._baseUrl + "api/AppUser/" )
                .subscribe((response: any) => {
                    this.allAppUsers = response;
                    resolve(this.allAppUsers);
                }, reject);
        });
    }
    


}

export interface IUser {
    name: string;
    email: string;
}
export interface IUserStatus {
    name: string;
    email: string;
    status: number;
}

export interface IMsgInterface {
    email: string;
    msg: string;
}