import { FuseNavigation } from "@fuse/types";

export const navigation: FuseNavigation[] = [
    {

        id: 'dashboard',
        title: 'DASHBOARD',
        type: 'item',
        url: '/ui/dashboard',
        classes: "NavMainItem",
    },

    {
        id: 'initmeet',
        title: 'INITIATE MEETING',
        type: 'item',
        url: '/ui/initmeet',
        classes: 'initMeetingNav',
        hidden: true,

    },
    {
        id: 'calendar',
        title: 'CALENDAR',
        type: 'item',
        url: '/ui/calendar',
        classes: "NavMainItem",

    },
   

    {
        id: 'globalsearch',
        title: 'SEARCH',
        type: 'item',
        url: '/ui/globalsearch',
        classes: 'NavMainItem',


    },
    {
        id: 'myProfile',
        title: 'MY PROFILE',
        type: 'item',
        // icon: 'fiber_manual_record',
        url: '/ui/myprofile',

        classes: 'NavMainItem'
    },
    {
        id: "MyMeetingMinutes",
        title: "MY RECORDS",
        //type: "group",
        type: "collapsable",
        classes: "NavMainItem",
        //icon: "pages",
        children: [

            {
                id: 'meetingMinutesmy',
                title: 'Meetings',
                type: 'item',
                //icon: 'view_day',
                url: '/ui/meetingminutesmy',
                classes: 'MyRecordsCustom',
            },
            {
                id: 'myActionItems',
                title: 'Activity List',
                type: 'item',
                //icon: 'view_day',
                url: '/ui/myactionitems',
                classes: 'MyRecordsCustom',
            },

            {
                id: 'participantverification',
                title: 'Verify Participation',
                type: 'item',
                //icon: 'view_day',
                url: '/ui/participantVerification',
                classes: 'MyRecordsCustom',
            },
            //{
            //    id: "boardpapers",
            //    title: "Board Papers",
            //    type: "collapsable",
            //    //icon: "view_day",
            //    classes: 'MyRecordsCustom',
            //    hidden: true,
            //    children: [

            //        {
            //            id: 'submitboardpapers',
            //            title: 'Submit',
            //            type: 'item',
            //            icon: 'assignment',
            //            url: '/ui/submitboardpapers',
            //            classes: 'MyRecordsChildCustom',
            //            hidden: true,
            //        },
            //        {
            //            id: 'myboardpapers',
            //            title: 'My Papers',
            //            type: 'item',
            //            icon: 'assignment_ind',
            //            url: '/ui/myboardpapers',
            //            classes: 'MyRecordsChildCustom',
            //            hidden: true,
            //        },
            //        {
            //            id: 'reviewboardpapers',
            //            title: 'Review Papers',
            //            type: 'item',
            //            icon: 'rate_review',
            //            url: '/ui/reviewboardpapers',
            //            classes: 'MyRecordsChildCustom',
            //            hidden: true,
            //        },
            //        {
            //            id: 'viewboardpapers',
            //            title: 'View Papers',
            //            type: 'item',
            //            icon: 'table_view',
            //            url: '/ui/viewboardpapers',
            //            classes: 'MyRecordsChildCustom',
            //            hidden: true,
            //        },
            //    ],
            //},
        ],
    },
    {
        id: "Meetings",
        title: "PAPERS",
        type: "collapsable",
        //icon: "widgets",
        classes: 'NavMainItem',
        hidden: true,
        children: [

            //{
            //    id: "Add-MeetingMinutes",
            //    title: "Add Meeting Minutes",
            //    type: "item",
            //    icon: "layers",
            //    url: "/ui/addmeetingminutes",
            //},

            {
                id: 'submitboardpapers',
                title: 'Submit',
                type: 'item',
                icon: 'assignment',
                url: '/ui/submitboardpapers',
                classes: 'MyRecordsChildCustom',
                hidden: true,
            },
            {
                id: 'myboardpapers',
                title: 'My Papers',
                type: 'item',
                icon: 'assignment_ind',
                url: '/ui/myboardpapers',
                classes: 'MyRecordsChildCustom',
                hidden: true,
            },
            {
                id: 'reviewboardpapers',
                title: 'Review Papers',
                type: 'item',
                icon: 'rate_review',
                url: '/ui/reviewboardpapers',
                classes: 'MyRecordsChildCustom',
                hidden: true,
            },
            {
                id: 'viewboardpapers',
                title: 'View Papers',
                type: 'item',
                icon: 'table_view',
                url: '/ui/viewboardpapers',
                classes: 'MyRecordsChildCustom',
                hidden: true,
            },
            
        ],
    },
    {
        id: 'initiatedmeetingdetails',
        title: 'MEETING MINUTES',
        type: 'item',
        //icon: 'widgets',
        url: '/ui/initiatedmeetingdetails',
        classes: 'NavMainItem',
        hidden: true,
    },
    {
        id: 'mattersarisingandactionitem',
        title: 'MATTERS ARISING',
        type: 'item',
        //icon: 'widgets',
        url: '/ui/mattersarisingandactionitem',
        classes: 'NavMainItem',
        hidden: true,
    },

    //{
    //    id: 'meetingMinutesmy',
    //    title: 'My Meeting Minutes',
    //    type: 'item',
    //    icon: 'layers',
    //    url: '/ui/meetingminutesmy'
    //},

    //{
    //    id: 'myDiscussionPoints',
    //    title: 'My Discussion Points',
    //    type: 'item',
    //    icon: 'layers',
    //    url: '/ui/mydiscussionpoints'
    //},

    {
        id: 'allActionItems',
        title: 'ACTIVITY LIST',
        type: 'item',
       // icon: 'fiber_manual_record',
        url: '/ui/allactionitems',
        classes: 'NavMainItem',
        hidden: true,

    },

    {
        id: 'meetingMinutesadmin',
        title: 'SUMMERY OF MEETINGS',
        type: 'item',
       // icon: 'fiber_manual_record',
        url: '/ui/meetingminutesadmin',
        classes: 'NavMainItem',
        hidden: true,
    },

    {
        id: "MeetingMinutes",
        title: "ADMINISTRATION",
        //type: "group",
        type: "collapsable",
        classes: "NavMainItem",
        //icon: "pages",
        children: [
          
            {
                id: 'startmeeting',
                title: 'Start/End Meeting',
                type: 'item',
                //icon: 'widgets',
                url: '/ui/startmeeting',
                classes: 'CommunicatorNavCustom',
                hidden: true,
            },
            {
                id: 'verifyyourparticipant',
                title: 'Verify Participants',
                type: 'item',
                //icon: 'widgets',
                url: '/ui/participantVerificationadmin',
                classes: 'CommunicatorNavCustom',
                hidden: true,
            },
            

            //{
            //    id: 'meetingroomscollapse',
            //    title: 'Meeting Rooms',
            //    type: 'collapsable',
            //    icon: 'widgets',
            //    classes: 'CommunicatorNavCustom',
            //    children: [
            //        {
            //            id: 'meetingrooms',
            //            title: 'Manage',
            //            type: 'item',
            //            icon: 'fiber_manual_record',
            //            url: '/ui/meetingrooms',
            //            classes: 'CommunicatorNavCustom'
            //        },
            //        {
            //            id: 'meetingbyrooms',
            //            title: 'Meeting Schedule',
            //            type: 'item',
            //            icon: 'fiber_manual_record',
            //            url: '/ui/meetingbyrooms',
            //            classes: 'CommunicatorNavCustom'
            //        },
            //    ]

            //},
            {
                id: "MeetingRoomDetail",
                title: "Meeting Rooms",
                type: "collapsable",
                //icon: "widgets",
                classes: 'CommunicatorNavCustom',
                hidden: true,
                children: [
                    {
                        id: 'meetingrooms',
                        title: 'Set-up',
                        type: 'item',
                        icon: 'fiber_manual_record',
                        url: '/ui/meetingrooms',
                        classes: 'CommunicatorNavCustom',
                        hidden: true,
                    },
                    {
                        id: 'meetingbyrooms',
                        title: "Today's Schedule",
                        type: 'item',
                        icon: 'fiber_manual_record',
                        url: '/ui/meetingbyrooms',
                        classes: 'CommunicatorNavCustom',
                        hidden: true,
                    },

                    {
                        id: 'managemeetingrooms',
                        title: 'Meeting Room Schedule',
                        type: 'item',
                        icon: 'fiber_manual_record',
                        url: '/ui/managemeetingrooms',
                        classes: 'CommunicatorNavCustom',
                        hidden: true,
                    },
                ],
            },
            //{
            //    id: 'meetingbyrooms',
            //    title: "Today's Meeting Room Schedule",
            //    type: 'item',
            //    //icon: 'widgets',
            //    url: '/ui/meetingbyrooms',
            //    classes: 'CommunicatorNavCustom',
            //    hidden: true,
            //},
            //{
            //    id: 'managemeetingrooms',
            //    title: 'Meeting Room Schedule',
            //    type: 'item',
            //    //icon: 'widgets',
            //    url: '/ui/managemeetingrooms',
            //    classes: 'CommunicatorNavCustom',
            //    hidden: true,
            //},         
            {
                id: 'usergroups',
                title: 'User Groups',
                type: 'item',
                //icon: 'widgets',
                url: '/ui/usergroups',
                classes: 'CommunicatorNavCustom',
                hidden: true,
            },
            {
                id: 'organizationregister',
                title: 'Company Registration',
                type: 'item',
                //icon: 'widgets',
                url: '/ui/organizationregister',
                classes: 'CommunicatorNavCustom',
                hidden: true,
            },
            {
                id: "ProfileManagement",
                title: "User Management",
                type: "collapsable",
                //icon: "widgets",
                classes: 'CommunicatorNavCustom',
                children: [
                    //{
                    //    id: 'myProfile',
                    //    title: 'My Profile',
                    //    type: 'item',
                    //    icon: 'fiber_manual_record',
                    //    url: '/ui/myprofile',

                    //    classes: 'CommunicatorNavCustom'
                    //},
                    {
                        id: 'userManagement',
                        title: 'Internal Users',
                        type: 'item',
                        icon: 'fiber_manual_record',
                        url: '/ui/usermanagement',
                        classes: 'CommunicatorNavCustom',
                        hidden: true,
                    },
                    {
                        id: 'externalParticipant',
                        title: 'External Users',
                        type: 'item',
                        icon: 'fiber_manual_record',
                        url: '/ui/externalparticipant',
                        classes: 'CommunicatorNavCustom',
                        hidden: true,
                    },
                ]
            },
            {
                id: "ReferenceData",
                title: "Reference Data",
                type: "collapsable",
                //icon: "widgets",
                classes: 'CommunicatorNavCustom',
                hidden: true,
                children: [
                    {
                        id: 'meetingcategory',
                        title: 'Meeting Category',
                        type: 'item',
                        icon: 'fiber_manual_record',
                        url: '/ui/meetingcategory',
                        classes: 'CommunicatorNavCustom',
                        hidden: true,
                    },

                    {
                        id: 'meetingproject',
                        title: 'Projects',
                        type: 'item',
                        icon: 'fiber_manual_record',
                        url: '/ui/meetingproject',
                        classes: 'CommunicatorNavCustom',
                        hidden: true,
                    },
                    {
                        id: 'departments',
                        title: 'Departments',
                        type: 'item',
                        icon: 'fiber_manual_record',
                        url: '/ui/departments',
                        classes: 'CommunicatorNavCustom',
                        hidden: true,
                    },
                    //{
                    //    id: 'meetingrooms',
                    //    title: 'Meeting Rooms',
                    //    type: 'item',
                    //    icon: 'fiber_manual_record',
                    //    url: '/ui/meetingrooms',
                    //    classes: 'CommunicatorNavCustom',
                    //    hidden: true,
                    //},

                ]
            },
            //{
            //    id: "refreshmentsetup",
            //    title: "Refreshments",
            //    type: "collapsable",
            //    //////icon: "widgets",
            //    classes: 'CommunicatorNavCustom',
            //    hidden: true,
            //    children: [
            //        {
            //            id: 'refreshmentsupplier',
            //            title: 'Refreshment Suppliers',
            //            type: 'item',
            //            icon: 'fiber_manual_record',
            //            url: '/ui/refreshmentsupplier',
            //            classes: 'CommunicatorNavCustom',
            //            hidden: true,
            //        },
            //        {
            //            id: 'refreshments',
            //            title: 'Manage Refreshments',
            //            type: 'item',
            //            url: '/ui/refreshments',
            //            icon: 'fiber_manual_record',
            //            classes: 'CommunicatorNavCustom',
            //            hidden: true,
            //        },

            //        {
            //            id: 'refreshmentorders',
            //            title: 'Order Refreshments',
            //            type: 'item',
            //            icon: 'fiber_manual_record',
            //            url: '/ui/refreshmentorders',
            //            classes: 'CommunicatorNavCustom',
            //            hidden: true,
            //        },
            //        {
            //            id: 'refreshmentrequest',
            //            title: 'Order Requests',
            //            type: 'item',
            //            icon: 'fiber_manual_record',
            //            url: '/ui/refreshmentrequest',
            //            classes: 'CommunicatorNavCustom',
            //            hidden: true,
            //        },
            //        {
            //            id: 'finalizeorder',
            //            title: 'Finalize Orders',
            //            type: 'item',
            //            icon: 'fiber_manual_record',
            //            url: '/ui/finalizeorder',
            //            classes: 'CommunicatorNavCustom',
            //            hidden: true,
            //        },
            //        {
            //            id: 'completedorders',
            //            title: 'Completed Orders',
            //            type: 'item',
            //            icon: 'fiber_manual_record',
            //            url: '/ui/completedorders',
            //            classes: 'CommunicatorNavCustom',
            //            hidden: true,
            //        },

            //    ]
            //},
        ],
    },











    //{
    //    id: 'allDiscussionPoints',
    //    title: 'Discussion Points',
    //    type: 'item',
    //    icon: 'layers',
    //    url: '/ui/alldiscussionpoints'
    //},

    {
        id: 'login',
        title: 'login',
        type: 'item',
        hidden: true,
        url: '/pages/authentication/login-2'
    },
    {
        id: "VideoConference",
        title: "BEAM",
        type: "collapsable",
        classes: "NavMainItem",
        hidden: true,
        //icon: "pages",
        children: [
            {
                id: 'videoboard',
                title: 'Join',
                type: 'item',
                icon: 'contacts',
                url: '/ui/videoboard',
                classes: 'AdministrationNavCustom'
            },
        ],
    },
];