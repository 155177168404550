import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { Observable } from "rxjs/Observable";

// import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        req = req.clone({
            setHeaders: {
                //"Content-Type": "application/json; charset=utf-8",
                Accept: "application/json",
                "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token, content-type",
                "Access-Control-Allow-Methods": "GET,POST, PATCH, PUT, DELETE, OPTIONS",
                "Access-Control-Allow-Origin": "*",
                Authorization: `Bearer ` + sessionStorage.getItem("emjwt"),
            },
        });

        return next.handle(req);
    }
}
