<!-- SIDE PANEL -->
<ng-container
    *ngIf="
        !fuseConfig.layout.sidepanel.hidden &&
        fuseConfig.layout.sidepanel.position === 'left'
    "
>
    <!-- CHAT PANEL -->
    <fuse-sidebar
        name="chatPanel"
        position="left"
        class="chat-panel left-chat-panel"
        [folded]="true"
        [foldedWidth]="70"
        [foldedAutoTriggerOnHover]="false"
        lockedOpen="gt-md"
    >
        <chat-panel></chat-panel>
    </fuse-sidebar>
    <!-- / CHAT PANEL -->
</ng-container>
<!-- / SIDE PANEL -->

<div id="main">
    <!-- TOOLBAR: Above -->
    <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'above'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Above -->

    <!-- NAVBAR: Top -->
    <ng-container>
        <ng-container *ngTemplateOutlet="topNavbar"></ng-container>
    </ng-container>
    <!-- / NAVBAR: Top -->

    <!-- TOOLBAR: Below -->
    <ng-container *ngIf="fuseConfig.layout.toolbar.position === 'below'">
        <ng-container *ngTemplateOutlet="toolbar"></ng-container>
    </ng-container>
    <!-- / TOOLBAR: Below -->

    <div id="container-1" class="container">
        <!-- NAVBAR: Left -->
        <ng-container>
            <ng-container *ngTemplateOutlet="leftNavbar"></ng-container>
        </ng-container>
        <!-- / NAVBAR: Left -->

        <div id="container-2" class="container">
            <div
                id="container-3"
                class="container"
                fusePerfectScrollbar
                [fusePerfectScrollbarOptions]="{
                    suppressScrollX: true,
                    updateOnRouteChange: true
                }"
            >
                <!-- CONTENT -->
                <content></content>
                <!-- / CONTENT -->

                <!-- FOOTER: Below -->
                <ng-container
                    *ngIf="fuseConfig.layout.footer.position === 'above-static'"
                >
                    <ng-container *ngTemplateOutlet="footer"></ng-container>
                </ng-container>
                <!-- / FOOTER: Below -->
            </div>
        </div>
    </div>

    <!-- FOOTER: Above -->
    <ng-container *ngIf="fuseConfig.layout.footer.position === 'above-fixed'">
        <ng-container *ngTemplateOutlet="footer"></ng-container>
    </ng-container>
    <!-- FOOTER: Above -->
</div>

<!-- SIDE PANEL -->
<ng-container
    *ngIf="
        !fuseConfig.layout.sidepanel.hidden &&
        fuseConfig.layout.sidepanel.position === 'right'
    "
>
    <!-- CHAT PANEL -->
    <fuse-sidebar
        name="chatPanel"
        position="right"
        class="chat-panel right-chat-panel"
        [folded]="true"
        [foldedWidth]="70"
        [foldedAutoTriggerOnHover]="false"
        lockedOpen="gt-md"
    >
        <chat-panel></chat-panel>
    </fuse-sidebar>
    <!-- / CHAT PANEL -->
</ng-container>
<!-- / SIDE PANEL -->

<!-- QUICK PANEL -->
<fuse-sidebar name="quickPanel" position="right" class="quick-panel">
    <quick-panel></quick-panel>
</fuse-sidebar>
<!-- / QUICK PANEL -->

<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS
<!-- ----------------------------------------------------------------------------------------------------- -->

<!-- TOOLBAR -->
<ng-template #toolbar>
    <toolbar
        *ngIf="!fuseConfig.layout.toolbar.hidden"
        [ngClass]="
            fuseConfig.layout.toolbar.customBackgroundColor === true
                ? fuseConfig.layout.toolbar.position +
                  ' ' +
                  fuseConfig.layout.toolbar.background
                : fuseConfig.layout.toolbar.position
        "
    >
    </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- FOOTER -->
<!-- <ng-template #footer>
    <footer
        *ngIf="!fuseConfig.layout.footer.hidden"
        [ngClass]="
            fuseConfig.layout.footer.customBackgroundColor === true
                ? fuseConfig.layout.footer.position +
                  ' ' +
                  fuseConfig.layout.footer.background
                : fuseConfig.layout.footer.position
        "
    ></footer>
</ng-template> -->
<!-- / FOOTER -->

<!-- TOP NAVBAR -->
<ng-template #topNavbar>
    <navbar
        variant="horizontal-style-1"
        class="top-navbar"
        fxHide
        fxShow.gt-md
        *ngIf="!fuseConfig.layout.navbar.hidden"
    >
    </navbar>
</ng-template>
<!-- / TOP NAVBAR -->

<!-- LEFT NAVBAR -->
<ng-template #leftNavbar>
    <fuse-sidebar
        name="navbar"
        class="navbar-fuse-sidebar"
        [folded]="fuseConfig.layout.navbar.folded"
        *ngIf="!fuseConfig.layout.navbar.hidden"
     >
        <navbar
            [variant]="fuseConfig.layout.navbar.variant"
            class="left-navbar"
        ></navbar>
    </fuse-sidebar>
</ng-template>
<!-- / LEFT NAVBAR -->
