import { Injectable } from '@angular/core'
import { Action } from '@ngrx/store'
//import { Action } from 'rxjs/internal/scheduler/Action'
import { ActivityListChartData } from "../models/dashboard.model"
export const ADD_CHARTDATA = 'ADD_CHARTDATA'

export class AddChartData implements Action {
    readonly type = ADD_CHARTDATA;

    constructor(public payload: ActivityListChartData) { }
}


export type Actions = AddChartData ;