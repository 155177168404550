import { Action } from '@ngrx/store'
import { MeetingsChartData } from "../models/meetings.model"
import * as AddMeetingsChartData from "../actions/meetings.action"

const initialState: MeetingsChartData = {
    Meetings: [],
}

export function ChartReducerMeeting(state: MeetingsChartData[] = [initialState], action: AddMeetingsChartData.Actions) {

    switch (action.type) {
        case AddMeetingsChartData.ADD_MeetingCHARTDATA:
            state = [];
            return [...state, action.payload];
        default:
            return state;
    }
}