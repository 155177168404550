import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
//import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule, Routes } from "@angular/router";
import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { InMemoryWebApiModule } from "angular-in-memory-web-api";
import { TranslateModule } from "@ngx-translate/core";

import { FuseModule } from "@fuse/fuse.module";
import { FuseSharedModule } from "@fuse/shared.module";
import {
    FuseProgressBarModule,
    FuseSidebarModule,
    FuseThemeOptionsModule,
} from "@fuse/components";

import { fuseConfig } from "app/fuse-config";


import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { AuthInterceptor } from "app/auth.interceptor";

//import { FakeDbService } from "app/fake-db/fake-db.service";
import { LoginComponent } from 'app/main/pages/authentication/login/login.component';
import { AppComponent } from "app/app.component";
import { LayoutModule } from "app/layout/layout.module";
import { AgmCoreModule } from '@agm/core';
import { StoreModule } from '@ngrx/store';
import { rootReduces } from "app/store/reducers/root.reducer";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatInputModule } from '@angular/material/input';
import { MatTableModule } from '@angular/material/table';
import { WrapperTable } from 'assets/angular-material-examples/table-wrapped/table-wrapped-example';
import { TableWrappedExample } from 'assets/angular-material-examples/table-wrapped/table-wrapped-example';
import { InputErrorStateMatcherExample } from 'assets/angular-material-examples/input-error-state-matcher/input-error-state-matcher-example';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
//import { MatExpansionModule } from "@angular/material/expansion";
import { MatSortModule } from "@angular/material/sort"
import { CdkTableModule } from '@angular/cdk/table';

//import { PickerModule } from '@ctrl/ngx-emoji-mart';

const appRoutes: Routes = [
    //{
    //    path: '', 
    //    redirectTo: "pages/auth/login-2",
    //},
    //{
    //    path: "apps",
    //    loadChildren: "./main/apps/apps.module#AppsModule",
    //},
    {
        path: "pages",
        loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    },
    {
        path: "ui",
        loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule),
    },

    //{
    //    path: "documentation",
    //    loadChildren:
    //        "./main/documentation/documentation.module#DocumentationModule",
    //},
    //{
    //    path: "angular-material-elements",
    //    loadChildren:
    //        "./main/angular-material-elements/angular-material-elements.module#AngularMaterialElementsModule",
    //},
    {
        path: "**",
        redirectTo: "pages/auth/login-2",
    },
    {
        path: "wrapper-table",
        component: WrapperTable
    },
    {
        path: "table-wrapped-example",
        component: TableWrappedExample
    },
    {
        path: "input-error-state-matcher-example",
        component: InputErrorStateMatcherExample
    }
];

@NgModule({
    declarations: [AppComponent, WrapperTable, InputErrorStateMatcherExample, TableWrappedExample], 
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        StoreModule.forRoot(rootReduces),
        TranslateModule.forRoot(),
        //InMemoryWebApiModule.forRoot(FakeDbService, {
        //    delay: 0,
        //    passThruUnknownUrl: true,
        //}),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule, MatInputModule, MatTableModule, 

        // App modules
        LayoutModule,

        FontAwesomeModule,
        FormsModule,
        ReactiveFormsModule,
       
        MatSortModule, CdkTableModule,

        //PickerModule
    ],
    
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
