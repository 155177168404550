import { Action } from '@ngrx/store'
import { ActivityListChartData } from "../models/dashboard.model"
import * as ChartData from "../actions/dashboard.action"

const initialState: ActivityListChartData = {
    CompletedItem: [],
    InprogressItem: [],
}

export function ChartReducer(state: ActivityListChartData[] = [initialState], action: ChartData.Actions) {

    switch (action.type) {
        case ChartData.ADD_CHARTDATA:
            state = [];
            return [...state, action.payload];
        default:
            return state;
    }
}